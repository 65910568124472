import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
//add your firebase configuration 
apiKey: "AIzaSyAW9X87jDJ9CIcsDKjPN47XDVNAYT5Hh3s",
  authDomain: "cdn-69.firebaseapp.com",
  databaseURL: "https://cdn-69-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "cdn-69",
  storageBucket: "cdn-69.appspot.com",
  messagingSenderId: "150667087421",
  appId: "1:150667087421:web:2864406dec328459f86454"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage };
